import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Company} from "../containers";
import {Header} from "../components";
import {Footer} from "../shared";
import {useLocation, useParams} from "react-router-dom";
import API from "../api";

export const CompanyPage = () => {

    const {companyId} = useParams();
    const {search} = useLocation()

    const openModal = () => ((search && search.includes('open_modal=y')) === true)

    const [company, setCompany] = useState(null);

    const fetchCompany = useCallback(async () => {
        const {data} = await API.companies.get(companyId)
        setCompany(data.item)

    }, [companyId])

    useEffect(() => {
        fetchCompany().then()
    }, [companyId, fetchCompany])

    return <Fragment>
        <main className={'h-100'} style={{backgroundColor: '#EEF2F8'}}>
            <Header />
            <div className={`container-xxl mt-3`} style={{minHeight: 800}}>
                {
                    company ?
                    <Company company={company} openModal={openModal()}/> :
                    <div>Loading.</div>
                }
            </div>
        </main>
        <Footer/>
    </Fragment>
}
