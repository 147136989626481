import React, {Fragment} from 'react';
import {Categories, EnterPromo} from "../../components";
import {toast} from "react-toastify";


export const Home = ({categories}) => {

    const onInputCode = (value) => {
        toast.error('Неправильный промокод')
    }

    return <Fragment>
        <EnterPromo onInputCode={onInputCode}/>
        <Categories categories={categories}/>
    </Fragment>
}
