import React from 'react';
import styles from './Input.module.scss'
export const Input = ({value, onChangeCode}) => {
    return   <div className={styles.root}>
        <label htmlFor="promo-code" className={styles.label}>
            Ввести код
        </label>
        <input
            placeholder={'Ввести код'}
            value={value}
            onChange={(e) => onChangeCode(e)}
            type="text"
            className={styles.field}
            id="promo-code"
        />
    </div>
}
