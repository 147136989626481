import React from 'react';
import styles from './Categories.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";

import 'swiper/css';

export const Categories = ({categories, width}) => {

    const slidesPerView = () => width <= 576 ? 3.5 :4

    const isLoop = () => width <= 576

    const spaceBetween = () => {
        if ( width <= 320){
            return 6
        }
        if ( width <= 576){
            return 8
        }
        if ( width <= 768){
            return 20
        }
        return 24
    }

    return (
        <Swiper
            spaceBetween={spaceBetween()}
            slidesPerView={slidesPerView()}
            loop={isLoop()}
        >
            {categories.map(({image}, index) => {
                return <SwiperSlide key={index}>
                    {image &&
                        <div className={styles.category}>
                            <div className={styles.categoryLogo} style={{backgroundImage: `url(${image})`}}>

                            </div>
                        </div>
                    }
                </SwiperSlide>
            })}
        </Swiper>
    )
}
