import React from 'react';
import styles from './Footer.module.scss'

export const Footer = () => {
    return <footer className={`text-center bg-dark text-white ${styles.root}`}>
        <div className={styles.address}>
            ООО «Веста»,<br/>
            ИНН 7801686610, <br/>
            Адрес: 199406, г. Санкт-Петербург, ул. Опочинина, <br/>
            д.16, лит. А, пом. 6Н, каб. 6,<br/>
            тел. +7(901)9761616
        </div>

        @ 2021 PromoKod.PRO
    </footer>
}
