import React from 'react';
import Slider from "react-animated-slider";
import './horizontal.css';
import styles from './Carousel.module.scss'

export const Carousel = ({width, slides}) => {
    return <Slider autoplay={5000} infinite={true} disabled={slides.length < 2}>
        {slides.map((slide, index) => {
            const {image} = slide
            let src = image.bg
            if (width > 320) {
                src = image.sm
            }
            if (width > 576) {
                src = image.lg
            }
            if (width > 992) {
                src = image.xl
            }
            return <div
                className={styles.slide}
                style={{backgroundImage: `url(${src})`}}
                key={index}/>
        })}
    </Slider>
}
