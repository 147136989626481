import React, {useContext} from 'react';
import {Col, Row} from "reactstrap";
import {Image} from "./Image";
import styles from './Offer.module.scss'
import {Categories} from "./Categories";
import {WindowsSizeContext} from "../WindowsSizeContext";
import {Button} from "../../shared";

export const Offer = ({offer, onClickGetPromo}) => {
    const {width} = useContext(WindowsSizeContext);

    const {logo, images = [], type, offerDescription, offerTitle} = offer

    return <div className={styles.root}>
        <div className={styles.images}>
            <div className={styles.brandLogo}>
                <Image src={logo} alt={offerTitle}/>
            </div>
            <div className={styles.categories}>
                <Categories width={width} categories={images}/>
            </div>
        </div>
        <Row className={styles.actions}>
            <Col>
                <h2 className={styles.title}>{offerTitle}</h2>
                <div className={styles.description} dangerouslySetInnerHTML={{__html: offerDescription}}/>
            </Col>
            <Col sm={'auto'} className={'text-center'}>
                <Button onClick={() => onClickGetPromo(offer)}>
                    <div className={styles.button}>
                        {type === 0 ? 'Открыть промокод' : ' Получить купон '}
                    </div>
                </Button>
            </Col>
        </Row>
    </div>
}
