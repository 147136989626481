import React, {useContext} from 'react';
import {Carousel} from "./Carousel";
import styles from './MainHeader.module.scss'
import {WindowsSizeContext} from "../WindowsSizeContext";
import {Logo} from "../../shared";


export const MainHeader = ({slides}) => {

    const {width} = useContext(WindowsSizeContext);

    return <header className={styles.header}>
        <div className={styles.logo}>
            <Logo/>
        </div>
        <h1 className={styles.title}>Ключ к&nbsp;скидкам</h1>
        <Carousel width={width} slides={slides}/>
    </header>
}
