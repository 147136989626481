import axios from "axios";

const baseURL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api.promokod.pro';

export const leads = {
    async create(values) {
        return await axios.post(`${baseURL}/leads`, values)
    },
}
