import React from 'react';
import {Logo} from "../../shared";
import styles from './Header.module.scss'

export const Header = () => {
    return <header className={styles.root}>
        <div className={styles.logo}>
            <Logo width={230}/>
        </div>
    </header>
}
