import React, {Fragment} from 'react';
import {Home} from "../containers";
import {Footer} from "../shared";
import {MainHeader} from "../components";

const slides = [
    {
        image: {
            bg: '/images/slider/slide-1.png',
            sm: '/images/slider/slide-1.png',
            lg: '/images/slider/slide-1.png',
            xl: '/images/slider/slide-1.png',
        }
    },
]

const categories = [
    {
        image: '/images/categories/category-1.png',
        name: 'Товары для дома'
    },
    {
        image: '/images/categories/category-2.png',
        name: 'Одежда'
    },
    {
        image: '/images/categories/category-3.png',
        name: 'Строительство\n' +
            'и ремонт'
    },
    {
        image: '/images/categories/category-4.png',
        name: 'Телефоны и гаджеты'
    },
    {
        image: '/images/categories/category-5.png',
        name: 'Спорт и отдых'
    },
    {
        image: '/images/categories/category-6.png',
        name: 'Автотовары'
    },
]

export const HomePage = () => {
    return <Fragment>
        <main className={'h-100'} style={{backgroundColor: '#F1C40F'}}>
            <MainHeader slides={slides}/>
            <Home categories={categories}/>
        </main>
        <Footer/>
    </Fragment>
}
