import { promo } from './actions/promo';
import { companies } from './actions/companies';
import { leads } from './actions/leads';

const API = {
  promo,
  companies,
  leads,
};

export default API;
