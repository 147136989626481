import axios from "axios";

const baseURL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api.promokod.pro';

export const companies = {
     async get(id) {
        return await axios.get(`${baseURL}/companies/${id}`)
    },
}
