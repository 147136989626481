import React, {useState} from 'react';
import vector from './img/vector.svg'
import {Container} from "reactstrap";
import {Input} from "./Input";

import style from './EnterPromo.module.scss'
import {Button} from "../../shared";

export const EnterPromo = ({width, onInputCode}) => {

    const [code, setCode] = useState('')

    const onChangeCode = (e) => {
        setCode(e.target.value)
    }

    const onClickInputCode = () => {
        onInputCode(code)
    }

    return <section className={style.root}>
        <Container className={'text-center'}>
            <h2 className={style.title}>Введите код</h2>
            <p className={style.caption}>и получите выгодные предложения</p>
            <img className={style.img} src={vector} alt=""/>
            <div className={style.field}>
                <Input value={code} onChangeCode={onChangeCode}/>
            </div>
            <Button disable={code.length === 0} onClick={onClickInputCode}>
                {width > 576 ? 'Перейти к предложениям': 'Получить промокод'}
            </Button>
        </Container>
    </section>
}
