import React from 'react';
import styles from './PromoCodeModal.module.scss'
import {Col, Modal, ModalBody, Row} from "reactstrap";
import {Button} from "../../shared";

export const PromoCodeModal = ({open , closeModal, company}) => {

    const {offerPromoCode, offerHidePromoCode, linkSite ,linkCompanyAddress,  linkPartner} = company

    const website = linkPartner || linkCompanyAddress || linkSite

    const copyPromoCode = () => {
        const valuePromoCodeInput = document.getElementById('value_promo_code');
        const message = document.getElementById('get-promo-code__message');
        const areaPromoCode = document.createElement('textarea');
        valuePromoCodeInput.appendChild(areaPromoCode);
        areaPromoCode.value = offerHidePromoCode || offerPromoCode;
        areaPromoCode.select();
        document.execCommand("copy");
        valuePromoCodeInput.removeChild(areaPromoCode);
        message.classList.remove("d-none")
    }

    return  <Modal toggle={closeModal} isOpen={open} size={'lg'}>
        <ModalBody>
            <div className={styles.modalRoot}>
                <div id="get-promo-code__message"
                     className="alert alert-success text-center mb-1 border border-success d-none"
                     role="alert">
                    Промокод скопирован в буфер
                </div>
                <div id="value_promo_code"/>
                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel} htmlFor="get-promo-code">Ваш промокод</label>
                    <input
                        className={styles.input}
                        id="get-promo-code"
                        value={offerPromoCode}
                        readOnly
                        onClick={copyPromoCode}
                    />
                </div>
                <Row>
                    <Col>
                        <Button onClick={copyPromoCode}>
                            Скопировать промокод
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className={'pt-5'}>
                        <a
                            href={website}
                            rel="noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            Перейти на сайт
                        </a>
                    </Col>
                </Row>
            </div>
        </ModalBody>
    </Modal>
}
