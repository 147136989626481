import React from 'react';
import {Col} from "reactstrap";
import styles from './Category.module.scss'
export const Category = ({category}) => {
    const {name, image} = category
    return <Col md={4} xs={6}>
        <div className={styles.content}>
            <img src={image} className={'img-fluid w-100'} alt=""/>
            <div className={`${styles.name} w-100`}>
            <h3 dangerouslySetInnerHTML={{__html: name}} className={`text-white`} />
            </div>
        </div>
    </Col>
}
