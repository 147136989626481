import React from 'react';
import {Container, Row} from "reactstrap";
import {Category} from "./Category";
import styles from './Categoryes.module.scss'
export const Categories = ({categories}) => {
    if (categories.length === 0){
        return ''
    }
    return <section>
        <Container className={`${styles.content} text-center`}>
            <h2 className={styles.header}>Категории товаров</h2>
            <Row>
                {
                    categories.map(
                        (category, index) =>
                            <Category key={index} category={category} />
                    )
                }
            </Row>
        </Container>
    </section>
}
