import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {CompanyPage, HomePage} from "./pages";
import {ToastContainer} from "react-toastify";
import {WindowsSizeContext} from "./components/";
import {useWindowSize} from "./hooks";

import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {

    const windowsSize = useWindowSize()

    return (
        <WindowsSizeContext.Provider value={windowsSize}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/companies/:companyId" element={<CompanyPage/>}/>
                </Routes>
            </BrowserRouter>
            <ToastContainer/>
        </WindowsSizeContext.Provider>
    );
};

export {App}
