import React, {Fragment, useState} from 'react';

import {Offer, PromoCodeModal} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import API from "../../api";

export const Company = ({company, openModal}) => {

    const [open, setOpen] = useState(() => {
        return openModal
    });

    const {pathname} = useLocation()
    const navigate = useNavigate();

    const onClickGetPromo = (offer) => {

        const {linkPartner, offerExternalId} = offer


        if(openModal){
            console.log('openModal')
            setOpen(true)
        }else{
            if (linkPartner){
                const obNewWindow = window.open(pathname + '?open_modal=y', '');
                API.leads.create({offer: offerExternalId});
                if (obNewWindow) {
                    window.location.assign(linkPartner);
                }
            }else {
                navigate(pathname + '?open_modal=y');
                setOpen(true)
            }
        }

    }

    const closeModal = () => {
        setOpen(false)
    }

    return <Fragment>
        <PromoCodeModal open={open} closeModal={closeModal} company={company}/>
        <Offer offer={company} onClickGetPromo={onClickGetPromo}/>
    </Fragment>
}
