import React from 'react';
import styles from './Button.module.scss'

export const Button = ({children, onClick, disable = false}) => {
    return <button
        onClick={onClick}
        type="button"
        className={styles.root}
        disabled={disable}
    >
        {children}
    </button>
}
