import axios from "axios";

const baseURL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api.promokod.pro';

export const promo = {
     async getByCode(code) {
        return await axios.get(`${baseURL}/get-by-code/${code}`)
    },
    async getBanners() {
        return await axios.get(`${baseURL}/get-banners`)
    },
    async getCategories() {
        return await axios.get(`${baseURL}/get-categories`)
    },
}
